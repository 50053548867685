import { Component, ComponentRef, InjectionToken, Injector, ValueProvider, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// eslint-disable-next-line
export const BASE_MODAL_INJECTION_TOKEN = new InjectionToken<UtilModalOptions>('BASE_MODAL_INJECTION_TOKEN');

// eslint-disable-next-line
export const MODAL_INSERTS_INJECTION_TOKEN = new InjectionToken<{component: BaseModalComponentType<any, any>, injector: Injector;}>('MODAL_INSERTS_INJECTION_TOKEN');


export type BaseModalComponentType<I = any, O = any> = new (...args: any[]) => BaseModalComponent<I, O>;


export class UtilModalRef<InputType = any, OutputType = any> {

  private resultSubject = new Subject<OutputType>();
  private resultAfterDelaySubject = new Subject<OutputType>();

  closingDelay = -1;

  private componentBehaviorSubject = new BehaviorSubject<ComponentRef<BaseModalComponentType<InputType, OutputType>>>(null!);

  setComponentRef(cmpRef: ComponentRef<BaseModalComponentType<InputType, OutputType>>) {
    this.componentBehaviorSubject.next(cmpRef);
  }

  close(result?: OutputType) {

    const internalClosingFn = () => {
      const value = this.componentBehaviorSubject.value;
      value?.destroy();
      this.resultAfterDelaySubject.next(result!);
      this.resultAfterDelaySubject.complete();
    };

    this.resultSubject.next(result!);
    this.resultSubject.complete();

    if (this.closingDelay < 0) {
      internalClosingFn();
    } else {
      setTimeout(() => internalClosingFn(), this.closingDelay);
    }


  }

  /**
   * waits until the user submits a result - does not include the time of a possible delay like an animation
   */
  waitForResult(): Observable<OutputType> {
    return this.resultSubject.asObservable();
  }

  /**
   * waits until the user submits a result and that includes a possible delay like an animation
   */
  waitForResultAfterDelay(): Observable<OutputType> {
    return this.resultAfterDelaySubject.asObservable();
  }
}

/**
 * @depricated for modals extending BaseModalComponent -> use provideMockModalDataFor() because it automatically referes data type of component argument and is typesaver
 */
export function provideMockModalData<I = any, O = any>(data?: I, modalDataBaseObject?: UtilModalOptions<I, O>): ValueProvider {

  const ref = new UtilModalRef<I, O>();

  const modalData = Object.assign({}, modalDataBaseObject, <UtilModalOptions<I, O>>{
    injectedData: data,
    modalRef: ref
  });

  return {
    provide: BASE_MODAL_INJECTION_TOKEN,
    useValue: modalData
  };

}

export function provideMockModalDataFor<I = any, O = any>(cmpRef: BaseModalComponentType<I, O>, data?: I, modalDataBaseObject?: UtilModalOptions<I, O>): ValueProvider {

  if (!cmpRef) {
    throw Error('no component ref was given as argument');
  }

  return provideMockModalData(data, modalDataBaseObject);

}



export interface UtilModalOptions<I = any, O = any> {
  modalRef?: UtilModalRef<I, O>;
  /**
   * an injector, which will be used as a parent injector for the modal.
   * Useful if the component (from which the modal is opened) has an injector that provides different data.
   * - An injector that differs from the injector of the root level
   */
  injector?: Injector;
  /**
   * A component, which will be dynamically created.
   * The component must inherit from a child or directly from AbstractModalComponent<I, O>
   */
  component?: BaseModalComponentType<I, O>;
  /**
   * (optional) data for the given component.
   * Can be accessed after the contructor (i.e. in "ngOnInit" or later)
   */
  injectedData?: I;
  /**
   * (optional) size of the modal
   */
  size?: 'xxs' | 'xs' | 'md' | 'xl' | 'xxl' | 'auto';
  /**
   * true by default
   */
  closeableWithBackdraftClick?: boolean;
  /**
   * true by default
   */
  closableByEscape?: boolean;
  focusElementOnClose?: HTMLElement;
}


@Component({
  template: ''
})
// I = Input Type, O = Output Type
export abstract class BaseModalComponent<I, O> {

  private _injectedModalOptions: UtilModalOptions<I, O>;

  get injectedModalOptions(): UtilModalOptions<I, O> {
    return this._injectedModalOptions;
  }

  get injectedModalData(): I {
    return this.injectedModalOptions?.injectedData;
  }



  constructor() {
    this._injectedModalOptions = inject(BASE_MODAL_INJECTION_TOKEN);
  }

  /**
   * returns Observable with a result
   */
  waitForResult(): Observable<O> {
    return this.injectedModalOptions.modalRef.waitForResult();
  }

  /**
   * returns Observable with a result after the delay set in the UtilModalRef
   */
  waitForResultAfterDelay(): Observable<O> {
    return this.injectedModalOptions.modalRef.waitForResultAfterDelay();
  }

  closeModal(result?: O) {
    this.injectedModalOptions.modalRef.close(result);
  }

  /**
   * @alias for closeModal()
   */
  close(result?: O) {
    this.closeModal(result);
  }

}
